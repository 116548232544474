export const defaultTheme = {
  primary: "#F4204E",
  accent: "#F7B70D",
  secondary: "#150F0C",
  text: "#9D9391",
  textAlt: "#6F6563",
  textDark: "#2D2727",
  background: "#F7F7F7",
  white: "#fff",
  black: "#000",
  gradient: {
    primary: "linear-gradient(135deg, #E70F74 0%, #FF2525 100%)",
    secondary: "linear-gradient(132.7deg, #E70F74 -2%, #FF2525 176.27%)",
  },
  button: {
    disabled: "#D5CDCC",
    secondary: "#FBEEEE",
    secondaryActive: "#FFE6E6",
    secondaryDisabled: "#F5F4F4",
    tertiary: "#4D4644",
  },
  input: {
    border: "#E8E8ED",
    placeholder: "#A59E9D",
    disabled: "#CFC9C8",
    dark: "#120F0E",
    darkActive: "#34302D",
    darkPlaceholder: "#ACACAC",
    selectButtonDark: "#47423E",
  },
};

import React from "react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

import { defaultTheme } from "src/styles/defaultTheme";
import favicon from "src/assets/images/favicon.ico";

export const wrapRootElement = ({ element }) => (
  <>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&&family=Poppins:wght@400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link rel="icon" href={favicon} />
      <title>Altruisto</title>
      <meta name="theme-color" content={defaultTheme.primary} />
      <meta
        name="description"
        content="Install an extension and when you buy something online, people in extreme poverty will get medicines, bed nets, or financial aid."
      />
      <meta
        name="keywords"
        content="browser extension, chrome, firefox, charity, charities, extreme poverty, malaria, schistosomiasis"
      />
    </Helmet>
    <ThemeProvider theme={defaultTheme}>{element}</ThemeProvider>
  </>
);
